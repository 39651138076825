
import { Component, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
import { KEY } from '@/inversify.keys';
// @ts-ignore
import CustomSelect, { Item } from '@/modules/common/components/ui-kit/custom-select.vue';
import type ClusterRatesService from '@/modules/cluster/cluster-rates.service';
import { SettingsGeneralService } from '@/modules/settings/settings-general.service';

@Component({
    components: { CustomSelect },
})
export default class RatesClusterNOGFilter extends Vue {
    @inject(KEY.ClusterRatesService) clusterRatesService!: ClusterRatesService;
    @inject(KEY.SettingsGeneralService) settingsGeneralService!: SettingsGeneralService;

    get value() {
        return this.clusterRatesService.storeState.settings.numberOfGuests || this.settingsGeneralService.defaultFilters.numberOfGuests;
    }

    set value(value: number) {
        this.clusterRatesService.storeState.settings.numberOfGuests = value;
    }

    get items(): Item[] {
        return Array
            .from({ length: 10 })
            .map((_, i) => i + 1)
            .map(e => ({
                value: e,
                name: String(e) + (e > 1 ? ' guests' : ' guest'),
            }));
    }
}
